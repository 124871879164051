// slick slider for the blog list component

$(document).ready(function(){
  $('.slick-single').slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    arrows: false
  });
  $('.blah').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    arrows: false,
    slidesToShow: 4
  });
});

function initTopSlider(){
  $('.top_slider').slick({
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button" style="display: block;">Previous</button>',
    nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="display: block;">Next</button>',
    responsive: [
     {
       breakpoint: 1300,
       settings: {
         centerMode: true,
         centerPadding: '40px',
         slidesToShow: 4
       }
     },
     {
       breakpoint: 1025,
       settings: {
         centerMode: true,
         centerPadding: '40px',
         slidesToShow: 4
       }
     },
     {
       breakpoint: 799,
       settings: {
         slidesToShow: 3
       }
     },
     {
       breakpoint: 480,
       settings: {
         arrows: true,
         centerMode: true,
         centerPadding: '40px',
         slidesToShow: 1
       }
     }
   ]
  });
}

// scroll to nav

$(".sub-nav > a").click(function() {
  var id = $(this).data("go");
	$('html, body').animate({
      scrollTop: $("#" + id).offset().top
  }, 1000);
  }
);

// subscribe popup
$('#js-newsletter__trigger').on('click', function(e) {
  e.preventDefault();
  $('#js-newsletter__screen').css('display', 'none').fadeIn('slow');
  $('body').css('overflow', 'hidden');
});

$('#js-newsletter__close').on('click', function(e) {
  e.preventDefault();
  $('#js-newsletter__screen').fadeOut('slow');
  $('body').css('overflow', 'initial');
});

// tabber

$('.tabber-switch').click(function (e) {
  $('.tabber-switch').removeClass("current");
  $(this).addClass("current");
  var tabberTargetId = $(this).data('tabber-switch-id');
  e.preventDefault();
  $('.tabber').hide();
  $('.tabber').each(function(i, v) {
    if ($(v).data('tabber-id') === tabberTargetId) {
      $(v).show().css('display', 'flex');
    }
  });
});

$("a").click(function() {
  var id = $(this).data("go");
  $('html, body').animate({
    scrollTop: $("#" + id).offset().top
  }, 1000);
});


var productCategories = new Vue({
    el: '#products',
    data: {
      categories: [],
      products:[],
      product:''
    },
    created: function () {

    },
    mounted() {
        axios.get("productCategories.json")
         .then(response => {
             this.categories = response.data.categories;
             this.products = response.data.categories[0].products;
             this.product = response.data.categories[0].products[0];
        });

    },
    updated() {
      initTopSlider();
    },
    methods: {
        pickCategory: function(x) {
          this.products = x.products;
          this.product = this.products[0];
        },
        pickProduct: function(x) {
          this.product = this.products.filter(y=>y.name==x)[0]
        }
    }

});
