(function() {
  "use strict";
  const button = document.getElementById("mobileNavigationMenu");
  const menuExpander = document.getElementById("jsMobileMenuExpander");
  const opened = menuExpander.className + " opened";
  const closed = menuExpander.className + " closed";

  window.onload = () => {
    menuExpander.className = closed;
  };

  button.addEventListener("click", () => {
	  menuExpander.className == closed ? menuExpander.className = opened : menuExpander.className = closed;
  }, false);
})();
